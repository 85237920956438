<template>
  <div class="swatches">
    <button
      v-for="(swatchProduct, index) in products"
      :key="`swatch-${index}`"
      :style="{ backgroundColor: swatchProduct.color_swatch?.hex }"
      class="swatch"
      :class="{ 'active-swatch': isActiveSwatch(swatchProduct.product), 'out-of-stock': isOutOfStock(swatchProduct) }"
      :title="swatchProduct.name"
      @click="handleSwatchClick(swatchProduct)"
    >
      <div
        v-if="isOutOfStock(swatchProduct)"
        class="out-of-stock"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'SwatchSelector',
  props: {
    products: {
      type: Array,
      required: true,
    },
    activeSwatch: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isOutOfStock(product) {
      if (!product) return false
      return Object.values(product?.markets)[0]?.stockOfAllItems === 0
    },
    isActiveSwatch(swatchProductId) {
      return this.activeSwatch?.product === swatchProductId
    },
    handleSwatchClick(swatchProduct) {
      this.$emit('swatch-click', swatchProduct)
    },
  },
}
</script>

<style scoped lang="scss">
.swatches {
  display: flex;
  gap: 1.6rem;
  margin: 0.5rem 0;

  .swatch {
    position: relative;
    appearance: none;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    border: none;
    cursor: pointer;

    &.active-swatch {
      outline: 2px solid #0d3fcc;
      outline-offset: 0.4rem;
    }

    &.out-of-stock {
      pointer-events: none;
      outline: none;
    }

    .out-of-stock {
      position: absolute;
      top: 0;
      left: 46%;
      transform: rotate(45deg);
      width: 10%;
      height: 100%;
      background-color: white;
      overflow: hidden;
    }
  }
}
</style>
