<template>
  <div class="dropdown">
    <button
      class="dropdown-label"
      :class="{ active: isActive }"
      :aria-expanded="isActive"
      :aria-controls="'dropdown-list'"
      @click="toggle"
    >
      <div class="swatch-circle-wrapper">
        <span
          class="swatch-circle"
          :style="{ backgroundColor: activeSwatch.color_swatch?.hex }"
        />
        <div class="swatch-line" />
      </div>
      <p>{{ activeSwatch.color }}</p>
      <div
        class="chevron"
        :class="{ active: isActive }"
      >
        <ChevronDownIcon />
      </div>
    </button>
    <ul
      v-show="isActive"
      id="dropdown-list"
      class="dropdown-list"
      role="listbox"
    >
      <li
        v-for="(swatchProduct, index) in products"
        :key="`swatch-${index}`"
        class="dropdown-item"
        :class="{ 'out-of-stock': isOutOfStock(swatchProduct) }"
        role="option"
        @click="handleSwatchClick(swatchProduct)"
      >
        <div class="swatch-circle-wrapper">
          <span
            class="swatch-circle"
            :style="{ backgroundColor: swatchProduct.color_swatch?.hex }"
          />
          <div :class="{ 'out-of-stock': isOutOfStock(swatchProduct) }" />
        </div>
        <span>{{ swatchProduct.color_swatch?.desc }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import ChevronDownIcon from '~/assets/icons/chevron-down-small.svg'

export default {
  name: 'SwatchDropdown',
  components: { ChevronDownIcon },
  props: {
    activeSwatch: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive
    },
    isOutOfStock(product) {
      if (!product) return false
      return Object.values(product?.markets)[0]?.stockOfAllItems === 0
    },
    handleSwatchClick(swatchProduct) {
      this.$emit('swatch-click', swatchProduct)
      this.isActive = false
    },
  },
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  cursor: pointer;
  margin-right: 1.6rem;

  .dropdown-label {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.8rem 1.2rem;
    border: 1px solid $blue;
    border-radius: 0.3rem;
    font-size: 1.2rem;
    cursor: pointer;
    p {
      margin: 0;
    }
    &.active {
      border-radius: 0.4rem 0.4rem 0 0;
    }
    .swatch-circle-wrapper {
      position: relative;
      height: 1.6rem;
      width: 1.6rem;
      margin-right: 1rem;
      .swatch-circle {
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }
    .chevron {
      margin-left: auto;
      width: 1.2rem;
      transition: all 0.2s ease;
      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-list {
    position: relative;
    width: 100%;
    border: solid $blue;
    border-width: 0px 1px 1px 1px;
    background-color: #fff;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 3;
    border-radius: 0 0 0.4rem 0.4rem;

    .dropdown-item {
      display: flex;
      align-items: center;
      padding: 0.8rem 1.2rem;
      font-size: 1.2rem;
      cursor: pointer;

      &:hover {
        background-color: #f0f0f0;
      }

      &.out-of-stock {
        pointer-events: none;
        opacity: 0.3;
      }

      .swatch-circle-wrapper {
        position: relative;
        height: 1.6rem;
        width: 1.6rem;
        margin-right: 1rem;

        .swatch-circle {
          display: block;
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
        .out-of-stock {
          position: absolute;
          top: 0;
          left: 46%;
          transform: rotate(45deg);
          width: 10%;
          height: 100%;
          background-color: $white;
        }
      }
    }
  }
}
</style>
