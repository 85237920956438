<template>
  <ul class="breadcrumbs">
    <li
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
    >
      <mp-link :to="$u(breadcrumb.slug)">
        {{ breadcrumb.label }}
      </mp-link>
      <ChevronIcon v-if="index < breadcrumbs.length - 1" />
    </li>
  </ul>
</template>


<script>
import ChevronIcon from '~/assets/icons/chevron-right-large.svg'

export default {
  name: 'Breadcrumbs',
  components: {
    ChevronIcon
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  flex-direction: row;
  padding: 1.6rem;
  @include text-field('small');
  text-transform: underline;
  svg {
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 0.8rem;
  }
    li {
      display: inline-block;

      a {
        text-decoration: underline;
        text-underline-offset: 0.2rem;
      }
     
    }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .breadcrumbs {
    font-size: 1.2rem;
    padding-left: spacing('medium');
  }
}
</style>
