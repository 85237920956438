<template>
  <div
    v-if="activeSwatch"
    :class="['bundle-section', { 'out-of-stock': !defaultProduct }]"
  >
    <div class="image-wrapper">
      <mp-link :to="$u(activeSwatch.url)">
        <Vue2Image
          v-if="imageUrl"
          :src="imageUrl"
          :alt="`product image for ${activeSwatch.name}`"
          :quality="70"
          :width="600"
          :widths="[320, 600]"
          class="product-image"
        />
      </mp-link>
    </div>

    <div class="details">
      <h3
        class="product-name"
        v-text="activeSwatch?.name"
      />
      <p
        class="product-description"
        v-text="activeSwatch?.excerpt"
      />
      <div class="price-wrapper">
        <p class="product-price-label">{{ $t('Bundle_Section_Price_Label') }}</p>
        <p class="product-price">{{ getProductPrice() }}</p>
      </div>

      <div class="swatches">
        <!-- Dropdown for mobile -->
        <SwatchDropdown
          v-if="!desktop && section.products.length > 1"
          :active-swatch="activeSwatch"
          :products="section.products"
          @swatch-click="handleSwatchClick"
        />
        <!-- Button swatch selector for desktop -->
        <SwatchSelector
          v-if="desktop && section.products.length > 1"
          :products="section.products"
          :active-swatch="activeSwatch"
          @swatch-click="handleSwatchClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Image from '@made-people/vue2-image'
import SwatchDropdown from './SwatchDropdown.vue'
import SwatchSelector from './SwatchSelector.vue'

export default {
  name: 'BundleSection',
  components: { Vue2Image, SwatchDropdown, SwatchSelector },
  props: {
    section: {
      type: Object,
      required: true,
    },
    desktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    defaultProduct: undefined,
    selectedProductId: undefined,
    activeSwatch: undefined,
    isActive: false,
  }),
  computed: {
    imageUrl() {
      return this.activeSwatch?.media?.[0]?.sources?.original?.url || ''
    },
  },
  watch: {
    activeSwatch: {
      handler(activeSwatch) {
        const itemId = Object.values(activeSwatch.items)[0]?.item
        this.$emit('bundle-section-active-swatch-changed', {
          itemId,
          sectionId: this.section.section.toString(),
        })
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultProduct = this.section.products.find((product) => !this.isOutOfStock(product))
    this.activeSwatch = this.defaultProduct ?? this.section.products[0]

    if (this.activeSwatch) {
      const itemId = Object.values(this.activeSwatch.items)[0]?.item
      this.$emit('bundle-section-active-swatch-changed', {
        itemId,
        sectionId: this.section.section.toString(),
      })
    }
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive
    },
    isOutOfStock(product) {
      if (!product) {
        return
      }
      return Object.values(product?.markets)[0]?.stockOfAllItems === 0
    },
    handleSwatchClick(swatchProduct) {
      this.activeSwatch = this.section.products.find((product) => product.product === swatchProduct.product)
      this.isActive = false
    },
    getProductPrice() {
      const firstEntry = Object.values(this.activeSwatch.markets)[0]
      const pricesByPricelist = firstEntry?.pricesByPricelist
      const firstPricelistEntry = Object.values(pricesByPricelist || {})[0]
      return firstPricelistEntry?.price || 'N/A'
    },
    isActiveSwatch(swatchProductId) {
      return this.activeSwatch?.product === swatchProductId
    },
  },
}
</script>

<style scoped lang="scss">
.bundle-section {
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 1.6rem;
  p {
    margin: 0;
  }
  &.out-of-stock {
    opacity: 0.5;
  }
  .image-wrapper {
    display: flex;
    a {
      height: fit-content;
    }
    .product-image {
      width: 100%;
      object-fit: contain;
    }
  }
  .details {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto 1fr;
    .product-name {
      grid-area: 1 / 1 / 2 / 3;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.5;
    }
    .product-description {
      grid-area: 2 / 1 / 3 / 3;
      font-size: 1.2rem;
    }
    .price-wrapper {
      grid-area: 3 / 2 / 4 / 2;
      display: flex;
      flex-direction: column;
    }
    .product-price-label {
      font-size: 1.1rem;
    }
    .product-price {
      font-size: 1.1rem;
    }
    .swatches {
      margin-top: 0.4rem;
      grid-area: 3 / 1 / 4 / 2;
    }
  }
}

@media screen and (min-width: $tablet-landscape) {
  .bundle-section {
    grid-template-columns: 96px 1fr;
    .details {
      grid-column-gap: 3.2rem;
      grid-row-gap: 0.8rem;

      .product-name {
        grid-area: 1 / 1 / 2 / 2;
        font-size: 1.6rem;
      }
      .product-description {
        grid-area: 2 / 1 / 3 / 2;
        font-size: 1.4rem;
      }
      .price-wrapper {
        grid-area: 1 / 2 / 4 / 2;
      }
      .product-price-label {
        font-size: 1.4rem;
      }
      .product-price {
        font-size: 1.4rem;
      }
    }
  }
}
</style>
