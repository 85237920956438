import { CentraSearchController } from '@made-people/nuxt-centra-search'
import Vue from 'vue'
import { BreadcrumbLoader } from '@made-people/nuxt-breadcrumbs'
import Breadcrumbs from './components/breadcrumbs/Breadcrumbs.vue'
import ProductDetails from '~/components/product-details/ProductDetails.vue'
import Overlay from '~/components/overlay/Overlay'
import CountrySelectorSidebar from '~/components/country-selector-sidebar/CountrySelectorSidebar.vue'
import BeforeAfterBlock from '~/components/before-after/BeforeAfterBlock.vue'
Vue.component('Breadcrumbs', Breadcrumbs)

Vue.component('BreadcrumbLoader', BreadcrumbLoader.default)

Vue.component('MpLink', () => import('@made-people/centra-storyblok-nuxt-shared/lib/components/MpLink.vue'))
Vue.component('ResponsiveImage', () => import('@made-people/centra-storyblok-nuxt-shared/lib/components/ResponsiveImage.vue'))
Vue.component('FindifyResultsController', () => import('@made-people/centra-storyblok-nuxt-shared/lib/components/findify/FindifyResultsController.vue'))
Vue.component('ProductListController', () => import('@made-people/centra-storyblok-nuxt-shared/lib/components/product-list/ProductListController.vue'))

Vue.component('CentraSearchController', CentraSearchController.default)
Vue.component('SearchResultsWrapper', () => import('~/components/search/SearchResultsWrapper.vue'))
Vue.component('SearchProductList', () => import('~/components/search/SearchProductList.vue'))

// Main views
/* eslint-disable */
Vue.component('storyblok', () => import('~/views/Storyblok.vue'))
Vue.component('product', () => import('~/views/Product.vue'))
Vue.component('ProductView', () => import('~/views/Product.vue'))
Vue.component('search', () => import('~/views/Search.vue'))
/* eslint-enable */

// Header
Vue.component('Header', () => import('~/components/header/Header.vue'))
Vue.component('Topbar', () => import('~/components/header/topbar/Topbar.vue'))
Vue.component('HeaderText', () => import('~/components/header/HeaderText.vue'))
Vue.component('CountrySelector', () => import('~/components/country-selector/CountrySelector.vue'))

// Footer
Vue.component('Footer', () => import('~/components/footer/Footer.vue'))
Vue.component('FooterLogos', () => import('~/components/footer-logos/FooterLogos.vue'))

// Sidebars and Popups
Vue.component('CookieNotice', () => import('~/components/cookie-notice/CookieNotice.vue'))
Vue.component('CountrySelectorSidebar', CountrySelectorSidebar)
Vue.component('MobileSidebar', () => import('~/components/header/MobileSidebar.vue'))
Vue.component('AddedToCartPopup', () => import('~/components/added-to-cart-popup/AddedToCartPopup.vue'))

// Content Blocks
Vue.component('Faq', () => import('~/components/faq/Faq.vue'))
Vue.component('ImageBlock', () => import('~/components/image/ImageBlock.vue'))
Vue.component('Toast', () => import('~/components/toast/Toast.vue'))
Vue.component('ShopUsps', () => import('~/components/shop-usps/ShopUsps.vue'))
Vue.component('Headline', () => import('~/components/headline/Headline.vue'))
Vue.component('Hero', () => import('~/components/hero/Hero.vue'))
Vue.component('HeroSwipeable', () => import('~/components/hero-swipeable/HeroSwipeable.vue'))
Vue.component('HeroSpecial', () => import('~/components/hero-special/HeroSpecial.vue'))
Vue.component('HeroSpecialItem', () => import('~/components/hero-special/HeroSpecialItem.vue'))
Vue.component('Button', () => import('~/components/button/Button.vue'))
Vue.component('ToggleButton', () => import('~/components/button/ToggleButton.vue'))
Vue.component('Grid', () => import('~/components/grid/Grid.vue'))
Vue.component('QuickNav', () => import('~/components/quick-nav/QuickNav.vue'))
Vue.component('Newsletter', () => import('~/components/newsletter/Newsletter.vue'))
Vue.component('Video', () => import('~/components/video/Video.vue'))
Vue.component('TextBlock', () => import('~/components/text-block/TextBlock.vue'))
Vue.component('AboutBlock', () => import('~/components/about/AboutBlock.vue'))
Vue.component('ShopMissionStatement', () => import('~/components/shop-mission-statement/ShopMissionStatement.vue'))
Vue.component('InfluencerTestimonials', () => import('~/components/influencer-testimonials/InfluencerTestimonials.vue'))
Vue.component('InfluencerTestimonial', () => import('~/components/influencer-testimonials/InfluencerTestimonial.vue'))
Vue.component('ShopTheLook', () => import('~/components/shop-the-look/ShopTheLook.vue'))
Vue.component('IngridTracking', () => import('~/components/ingrid-tracking/IngridTracking'))

Vue.component('References', () => import('~/components/references/References.vue'))
Vue.component('Quote', () => import('~/components/quote/Quote.vue'))

// Reviews
Vue.component('CommunityReviews', () => import('~/components/community-reviews/CommunityReviews.vue'))
Vue.component('YotpoReviews', () => import('~/components/yotpo/reviews/YotpoReviews.vue'))

// Product List
Vue.component('ProductCardCentra', () => import('~/components/product-card-centra/ProductCardCentra.vue'))
Vue.component('ProductCardUpsell', () => import('~/components/product-card-upsell/ProductCardUpsell.vue'))
Vue.component('ProductListCentra', () => import('~/components/product-list-centra/ProductListCentra.vue'))
Vue.component('ProductListSwipeable', () => import('~/components/product-list-swipeable/ProductListSwipeable.vue'))
Vue.component('ProductListSwipeableUpsell', () => import('~/components/product-list-swipeable-upsell/ProductListSwipeableUpsell.vue'))

// Product Page
Vue.component('ProductStoryblokPDPProduct', () => import('~/components/product-storyblok-pdp-product/ProductStoryblokPDPProduct.vue'))
Vue.component('ProductDetailPageCentra', () => import('~/components/product-detail-page-centra/ProductDetailPageCentra.vue'))
Vue.component('ProductImageGallery', () => import('~/components/product-image-gallery/ProductImageGallery.vue'))
Vue.component('ProductDetails', ProductDetails)
Vue.component('ProductSpecification', () => import('~/components/product-specification/ProductSpecification.vue'))
Vue.component('BeforeAfterSelector', () => import('~/components/before-after/BeforeAfterSelector.vue'))
Vue.component('BeforeAfterBlock', BeforeAfterBlock)
Vue.component('ProductRestockPopup', () => import('~/components/product-restock-popup/ProductRestockPopup.vue'))
Vue.component('ProductTag', () => import('~/components/product-tag/ProductTag.vue'))
Vue.component('ProductKeyIngredients', () => import('~/components/product-key-ingredients/ProductKeyIngredients.vue'))
Vue.component('ProductBadge', () => import('~/components/product-badge/ProductBadge.vue'))
Vue.component('SpinningBadge', () => import('~/components/spinning-badge/SpinningBadge.vue'))
Vue.component('ProductPageRelatedProducts', () => import('~/components/product-page-related-products/ProductPageRelatedProducts.vue'))
Vue.component('ProductPageVideo', () => import('~/components/product-page-video/ProductPageVideo.vue'))
Vue.component('ProductPageKeyIngredients', () => import('~/components/product-page-key-ingredients/ProductPageKeyIngredients.vue'))
Vue.component('ProductPageSpecification', () => import('~/components/product-page-specification/ProductPageSpecification.vue'))

// Checkout
Vue.component('Checkout', () => import('~/components/checkout/Checkout.vue'))
Vue.component('CheckoutAddress', () => import('~/components/checkout/checkout-address/CheckoutAddress.vue'))
Vue.component('CheckoutAddressPreview', () => import('~/components/checkout/checkout-address/CheckoutAddressPreview.vue'))
Vue.component('CheckoutMethod', () => import('~/components/checkout/checkout-method/CheckoutMethod.vue'))
Vue.component('CheckoutMethodRadio', () => import('~/components/checkout/checkout-method-radio/CheckoutMethodRadio.vue'))
Vue.component('CheckoutResult', () => import('~/components/checkout/checkout-result/CheckoutResult.vue'))
Vue.component('CheckoutResultReceipt', () => import('~/components/checkout/checkout-result/CheckoutResultReceipt.vue'))
Vue.component('CheckoutResultReceiptProductCard', () => import('~/components/checkout/checkout-result/CheckoutResultReceiptProductCard.vue'))
Vue.component('CheckoutShippingPayment', () => import('~/components/checkout/checkout-shipping-payment/CheckoutShippingPayment.vue'))
Vue.component('Discount', () => import('~/components/discount/Discount.vue'))
Vue.component('CheckoutAgreeTerms', () => import('~/components/checkout/checkout-agree-terms/CheckoutAgreeTerms.vue'))

// Helpers and Sanitary
Vue.component('TransitionExpand', () => import('~/components/transition-expand/TransitionExpand.vue'))
Vue.component('Overlay', Overlay)
Vue.component('Banner', () => import('~/components/banner/Banner.vue'))
Vue.component('MarketContainer', () => import('@made-people/centra-storyblok-nuxt-shared/lib/components/MarketContainer.vue'))
Vue.component('Timer', () => import('~/components/timer/Timer.vue'))

// Blog
Vue.component('BlogPostSummary', () => import('~/components/blog-post-summary/BlogPostSummary.vue'))
Vue.component('SwipeableBlogList', () => import('~/components/blog-swipeable-list/BlogSwipeableList.vue'))
Vue.component('BlogList', () => import('~/components/blog-list/BlogList.vue'))
Vue.component('BlogStylizedBlock', () => import('~/components/blog-stylized-block/BlogStylizedBlock.vue'))
Vue.component('BlogCategoryNavbar', () => import('~/components/blog-category-navbar/BlogCategoryNavbar.vue'))

// Yotpo
Vue.component('InstagramGallery', () => import('~/components/instagram-yotpo/InstagramGallery'))
Vue.component('InstagramGalleryPdp', () => import('~/components/instagram-yotpo/InstagramGalleryPdp'))
Vue.component('InstagramGalleryController', () => import('~/components/instagram-yotpo/InstagramGalleryController'))
Vue.component('InstagramImage', () => import('~/components/instagram-yotpo/InstagramImage'))
Vue.component('InstagramPopup', () => import('~/components/instagram-yotpo/InstagramPopup'))
Vue.component('AccordionBlock', () => import('~/components/accordion-block/AccordionBlock.vue'))
Vue.component('AccordionItem', () => import('~/components/accordion-block/AccordionItem.vue'))
Vue.component('Title', () => import('~/components/title/Title.vue'))

Vue.component('SubNavigation', () => import('~/components/sub-navigation/SubNavigation.vue'))
