<template>
  <div
    class="pdp-details"
    :class="{ loading: loading }"
  >
    <div
      v-if="!product.inStock"
      class="pdp-details__sold-out"
    >
      <span>
        <ProductBadge
          :label="$t('Global_Sold_Out')"
          type="sold-out"
        />
      </span>
    </div>
    <BreadcrumbLoader :child-component-name="'Breadcrumbs'" />
    <div class="info">
      <ProductDetailsReviews
        :product-id="product.sku"
        :product-name="product.name"
        :product-url="$u(product.url)"
      />
      <div class="details">
        <div class="name-n-type">
          <h1 class="name">
            {{ product.name }}
          </h1>
          <h2 class="type">
            {{ product.shortDescription }}
          </h2>
        </div>
        <span
          id="price"
          class="price"
        >
          <p :class="salePriceClass">{{ getPrice() }}</p>
          <p
            v-if="product.discounted || isFlexibleBundle"
            class="old-price"
          >
            {{ getPriceBeforeDiscount() }}
          </p>
        </span>
      </div>
      <LowestPrice
        v-if="product.lowestPrice && product.lowestPrice.length && product.discounted"
        :product="product"
      />

      <ProductSwatch
        :product-id="product.product"
        :swatches="product.swatchProducts"
        :on-pdp="true"
        @setSwatch="(payload) => $emit('setSwatch', payload)"
      />
      <BundleDetails
        v-if="isFlexibleBundle || isFixedBundle"
        :product="product"
        :product-id="product.product"
        :desktop="desktop"
        @bundle-section-active-swatch-changed="onActiveSwatchChanged"
      />
      <Alert
        v-if="statusMessage"
        class="info__status-message"
        :message="statusMessage"
      />

      <div class="add-to-cart hide-on-mobile">
        <div
          v-if="!product.inStock && notifyMeEnabled"
          class="button medium solid atc"
          @click="showProductRestockSidebar(selectedSize.value)"
        >
          <span class="label">
            {{ $t('Global_Out_Of_Stock') }} -
            {{ $t('Product_Details_Notify_Me') }}
          </span>
        </div>
        <button
          v-else
          class="button medium solid atc"
          :class="{ loading: loading }"
          :disabled="loading || !product.inStock || !selectedSize || cartExceedStock"
          @click="isFlexibleBundle ? addBundleToCart() : addToCart()"
        >
          <template v-if="!loading">
            <span
              v-if="!product.inStock || cartExceedStock"
              class="label"
            >
              {{ $t('Global_Out_Of_Stock') }}
            </span>
            <span
              v-else-if="selectedSize"
              class="label"
            >
              {{ $t('Product_Details_Add_To_Cart') }}
            </span>
          </template>
          <template v-else>
            <span class="label">
              <span class="spinner2" />
            </span>
          </template>
        </button>
      </div>
      <div
        v-if="product.usp"
        class="product-tags"
      >
        <ProductTag
          v-for="(tag, index) in productTags"
          :key="index"
          :label="tag"
        />
      </div>
      <div
        v-if="false && pdpUsps"
        class="global-usps"
      >
        <ul>
          <li
            v-for="(usp, index) in pdpUsps"
            :key="index"
          >
            <CheckUspIcon />
            <span>
              {{ usp.text }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ProductDetailsReviews from '@/components/product-details-reviews/ProductDetailsReviews'
import CheckUspIcon from '~/static/images/check-usp.svg'
import ProductBadge from '~/components/product-badge/ProductBadge.vue'
import ProductSwatch from '~/components/product-swatch/ProductSwatch.vue'
import Alert from '~/components/alert/Alert.vue'
import LowestPrice from '~/components/lowest-price/LowestPrice.vue'
import BundleDetails from '~/components/flexible-bundle/BundleDetails.vue'

export default {
  name: 'ProductDetails',
  components: {
    ProductBadge,
    ProductDetailsReviews,
    CheckUspIcon,
    ProductSwatch,
    Alert,
    LowestPrice,
    BundleDetails,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    statusMessage: {
      type: String,
      default: '',
    },
    desktop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bundleDetails: undefined,
      selectedSwatch: 1,
      selectedSize: null,
      truncated: true,
      sections: [],
    }
  },
  computed: {
    ...mapState({
      notifyMeEnabled: (state) => state.ui['restock-notification'].isEnabled,
    }),
    ...mapGetters({
      cart: 'centra-cart/cart',
    }),
    crumbs() {
      return [
        {
          position: 1,
          name: this.$t('Global_Home'),
          url: this.$u(''),
        },
        {
          position: 2,
          name: `${this.product.silkProductName}`,
          url: this.$u(this.product.url),
        },
      ]
    },
    salePriceClass() {
      return this.product.discounted || this.isFlexibleBundle ? 'sale-price' : ''
    },
    salePercentage() {
      return this.product.discountPercent + '% off'
    },
    pdpUsps() {
      return this.$store.state.storyblok.stories.find((x) => x.data?.content?.component === 'CountryInformation')?.data?.content?.pdpUsps
    },
    productTags() {
      return Object.values(this.product.usp).map((usp) => {
        if (usp.label) return usp.label
        return usp
      })
    },
    cartExceedStock() {
      let cartExceedStock
      if (this.cart && this.cart.items && this.cart.items)
        this.cart.items.forEach((item) => {
          if (this.product.product === item.product) cartExceedStock = item.quantity + 1 > this.stock
        })
      return cartExceedStock
    },
    stock() {
      return this.product.sizeOptions[0].quantity
    },
    isFixedBundle() {
      return this.bundleDetails?.bundle?.bundleInfo?.type === 'fixed'
    },
    isFlexibleBundle() {
      return this.bundleDetails?.bundle?.bundleInfo?.type === 'flexible'
    },
  },
  mounted() {
    if (this.product.bundleInfo) {
      this.getBundle()
    }
  },
  created() {
    this.selectedSize = this.product.isOnesize ? this.product.sizeOptions[0] : null
  },
  methods: {
    ...mapActions('centra-product', ['lookupBundle']),
    async fetchBundleData(bundleId) {
      try {
        const bundleData = await this.lookupBundle(bundleId)
        return bundleData
      } catch (error) {}
    },
    async getBundle() {
      this.bundleDetails = await this.fetchBundleData(this.product?.product)
    },
    onActiveSwatchChanged({ itemId, sectionId }) {
      // Find the index of an existing item within the same section
      const existingItemIndex = this.sections.findIndex((section) => section.section === sectionId)

      if (existingItemIndex !== -1) {
        // Replace the existing item with the new item
        this.sections.splice(existingItemIndex, 1, {
          section: sectionId,
          item: itemId,
        })
      } else {
        // Add the new item to the selectedItems array
        this.sections.push({
          section: sectionId,
          item: item.itemId,
        })
      }
    },
    addToCart() {
      this.$emit('addToCart', { selectedSize: this.selectedSize })
    },
    addBundleToCart() {
      const productId = Object.values(this.product.items)[0]?.item
      this.$emit('addBundleToCart', productId, 1, this.sections)
    },
    setSize(size, quantity /* , value */) {
      if (quantity > 0) {
        this.selectedSize = size
      } else if (this.notifyMeEnabled) {
        this.showProductRestockSidebar()
      }
    },
    showProductRestockSidebar(id) {
      if (id) {
        this.$store.dispatch('ui/restock-notification/show', {
          itemId: id,
          product: this.product,
        })
      }
    },
    getPrice() {
      if (this.isFlexibleBundle) {
        return this.bundleDetails?.bundle?.bundleInfo?.priceMaxBeforeDiscount
      } else {
        return this.product.price
      }
    },
    getPriceBeforeDiscount() {
      if (this.isFlexibleBundle) {
        return this.bundleDetails?.bundle?.bundleInfo?.priceOfItems
      } else {
        return this.product.priceBeforeDiscount
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pdp-details {
  padding: spacing('xsmall') 0;

  .pdp-details__sold-out {
    width: fit-content;
    margin-top: spacing('xxsmall');
    margin-bottom: spacing('xxsmall');
    padding-left: var(--side-padding);
    padding-right: var(--side-padding);
  }

  .breadcrumbs {
    padding: 0 var(--side-padding);
    margin-bottom: spacing('xsmall');
  }

  .info {
    position: relative;

    .yotpo-star-wrapper {
      padding-left: var(--side-padding);
      padding-right: var(--side-padding);

      &::v-deep {
        .yotpo-bottomline {
          .yotpo-icon {
            color: $yellow;
          }

          .yotpo-stars {
            margin-right: 0.5rem;
          }

          .text-m {
            margin-top: 1px;
          }
        }
      }
    }

    .details {
      padding: 0 var(--side-padding);
      margin-top: 0;
      display: grid;
      grid-template-columns: 2fr 1fr;

      .name-n-type {
        .name {
          @include h--tiny;
          display: block;
          margin: 0;
        }

        .type {
          @include p--medium;
          margin-top: 0.8rem;
        }
      }

      .price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        @include h--tiny;
        margin: 0;

        > p {
          margin: 0;
        }

        .sale-price,
        .old-price {
          display: inline-block;
        }

        .sale-price {
          color: $blue;
        }

        .old-price {
          color: $black;
          text-decoration: line-through;
        }
      }
    }

    .description {
      padding: 0 var(--side-padding);
      @include p--medium;
      margin-bottom: 1.6rem;

      p {
        margin: 0;
      }

      .label {
        font-weight: bold;
        margin-bottom: 0.6rem;
      }

      .descr-wrap {
        &.truncated {
          max-height: 10rem;
          mask-image: -webkit-gradient(linear, left 50%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
          -webkit-mask-image: -webkit-gradient(linear, left 50%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
        }
      }

      .read-more-btn {
        margin: 0.8rem 0 2.4rem 0;
        position: relative;
        z-index: 1;

        span {
          border-bottom: 1px solid $black;
          cursor: pointer;

          @media (hover: hover) {
            &:hover {
              border-bottom: 0;
            }
          }
        }
      }
    }

    .notify-me {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-left: 1rem;
        height: 1.6rem; // width as well
      }
    }

    .size-selector {
      margin: 0.6rem 0 0 0;
      padding: 0 calc(var(--side-padding) - 0.4rem);
      display: block;

      .overlay {
        display: block;
        position: fixed;
        z-index: 20;
        background: rgba($black, 0.8);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        .size {
          @include button-reset;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-grow: 0;
          border: 1px solid $blue;
          border-radius: 2rem;
          flex-basis: calc(20% - 0.8rem);
          margin: 0.4rem;
          height: 2.8rem;
          position: relative;
          cursor: default;

          &.sold-out {
            //border: 1px solid rgba($black,0.1);
            border: none;
            overflow: hidden;
            box-shadow: inset 0 0 0 1px rgba($black, 0.2);
            background: $grey;

            .name {
              opacity: 0.5;
            }

            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              background: linear-gradient(to bottom right, transparent calc(50% - 0.7px), rgba($black, 0.2), transparent calc(50% + 0.7px));
            }
          }

          &.selected:not(.one-size) {
            background: $blue;

            .name {
              color: $white;
            }
          }

          &:hover:not(.selected):not(:disabled) {
            border-color: $black;
            cursor: pointer;
          }

          .name {
            @include p--medium;
            margin: 0;
          }

          .notify-hint {
            display: none;
          }

          &.show-notify-hint {
            @media (hover: hover) {
              &:hover {
                &:after {
                  display: none;
                }

                .name {
                  display: none;
                }

                .notify-hint {
                  @include p--small;
                  font-size: 0.9rem;
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    .add-to-cart {
      padding: 0 var(--side-padding);
      margin: 1.6rem 0;

      .button {
        position: relative;
        width: 100%;
        margin-bottom: 0.8rem;
        height: 4.4rem;

        &:last-of-type {
          margin-bottom: 0;
        }

        .spinner2 {
          border-top-color: $white;
          border-right-color: $white;
          border-bottom-color: $white;
        }

        &.loading {
          .label {
            opacity: 1;
          }
        }
      }

      .notify-me-icon {
        // display: none;
      }
    }

    .info__status-message {
      margin: 0 var(--side-padding);
    }

    .product-tags {
      display: flex;
      flex-wrap: wrap;
      padding: 0 var(--side-padding);
      margin-top: spacing('xsmall');
      gap: 0.4rem;
    }

    .global-usps {
      padding: 0 var(--side-padding);
      @include no-list;
      margin: 2.4rem 0;

      ul li {
        display: flex;
        align-items: center;
        @include p--small;
        margin: 0.8rem 0;

        svg {
          margin-right: 0.8rem;
        }
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  // ...
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .pdp-details {
    --side-padding: 0;
    padding: 0;

    .pdp-details__sold-out {
      margin-top: 0;
      margin-bottom: spacing('xsmall');
    }

    .breadcrumbs {
      margin-bottom: spacing('small');
    }

    .info {
      margin-bottom: 0.8rem;

      .details {
        display: flex;
        gap: spacing('small');
        justify-content: space-between;
        margin-bottom: 2.4rem;

        .name-n-type {
          .name {
            @include h--large;
            margin-top: 0;
            margin-bottom: spacing('xsmall');
          }

          .type {
            @include label('large');
          }
        }
      }

      .product-tags {
        margin: 1.6rem 0;
        gap: 0.8rem;
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  // ...
}
</style>
